import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import SEO from "../components/seo"
import Hero from "../components/hero"
import Features from "../components/features"
import Cards from "../components/cards"
import Explainer from "../components/explainer"
import SimpleLogos from "../components/simplelogos"
import Landing from "../components/landing"

import Plan from "../components/plan"
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import partnerLogo from "../images/shopify-partner-logo.png"
import partnerLogos from "../images/partner-logos@4x.png"

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "enterprise-hero@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const EnterprisePage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    <Landing
      headline={<span>Want to master <u>Shopify?</u> Forget the CTO and hire us.</span>}
      dek="You focus on building your business. We’ll handle all the tech."
      hero={
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      }
      cta="Find out more"
      bullets={[
        {icon: "ti-bar-chart", text: "Improve your conversion rates and performance"},
        {icon: "ti-settings", text: "Manage apps and complex integrations"},
        {icon: "ti-check-box", text: "Fix bugs, theme issues, and design tweaks"},

      ]}
      />
    <Features
      logo={partnerLogo}
      nuts={[
        {
          icon: "ti-timer",
          heading: "Rocket fast turnaround",
          text: "We often complete tasks the same day you ask. We even have 1-hour turnarounds for emergency issues."
        },
        {
          icon: "ti-star",
          heading: "Qualified expert help",
          text: "The top one percent of Shopify talent ready to help your business continue to scale. "
        },
        {
          icon: "ti-heart",
          heading: "Super easy to buy",
          text: "Our team knows how to navigate procurement. The payoff for you? Quick help and great terms."
        }
      ]}
    />
    <SimpleLogos
      logos={partnerLogos}
    />
    <Explainer
      para={(<>
              <p className="title-subtitle title-subtitle-dek mx-auto">
                  Rapid growth is the dream, right? But as your operations
                  multiply, things become more complex.
              </p>
              <p className="title-subtitle mx-auto">
                  Larger stores mean higher risks, more to juggle. You don’t
                  want to spend weeks on the phone with various vendors who
                  point fingers at each other. More partners means more
                  confusion.
              </p>
              <p className="title-subtitle mx-auto">
                  You’re moving a mile a minute and can’t wait for your
                  technology to catch up. We’ll cut through all the noise
                  to get you a solution, fast. We make sure nothing lags and
                  enable you to keep sprinting.
              </p>
            </>)}
      sections={[
        {
          heading:"Free up bandwidth",
          text:"Shift the tactical work and vendor management woes off your plate. Use that newfound time to generate the strategic initiatives that impact your business."
        },
        {
          heading:"Turn your ideas into reality, fast",
          text:"Generating ideas at a mile a minute? Our talent network means you won’t have to search for just the right person. We can quickly implement your vision, making agility your competitive advantage."
        },
        {
          heading:"Catch issues early",
          text:"Your operation is multiplying. With enterprise-grade monitoring, a seasoned team will be watching your site, 24/7, looking for problems before they affect your bottom line. Lightning-fast SLAs ensure rapid issue resolution."
        },
        {
          heading:"Transparency you can depend on",
          text:"With proactive status updates and weekly check-ins, you’ll always know the status of hot issues and be able to reliably make commitments to your stakeholders. You can keep your foot on the gas; we’ll make sure the tank is always full."
        },


      ]}
    />
    <Plan
      headline={<span>Get started</span>}
      steps={[
        {heading:'Get in touch', text:'Get in touch with our team.'},
        {heading:'Build your plan', text:'TKTKTKTK'},
        {heading:'Consider it done', text:'Focus on growing your business and keeping your customers happy.'}
      ]}
    />

    {/*<Cards
      headline="Get more done"
      dek="On the enterprise plan you'll get access to a range of value added services that will accellerate your agility."
      cards={[
        {heading:'Staffing', text:'Quickly add full-time and contingent staff with shopify experience to your team.'},
        {heading:'Content', text:'Innovative content solutions for native and branded media campaigns.'},
        {heading:'Research', text:'Fact and science based insights for e-commerce leaders'},
        {heading:'Labs', text:'Future facing Shopify e-commerce tools and innovations'}
      ]}
    />*/}
  </Layout>
)

export default EnterprisePage
